<template>
  <div class="zsCheckInContainer">
    <van-nav-bar :title="pageTitle"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content"
         :style="showBtn?'padding-bottom:1.06667rem;':'padding-bottom:0rem;'">
      <van-form>
        <div class="peField">
          <van-field disabled
                     required
                     right-icon="edit"
                     v-model="form.empname"
                     label="姓名"
                     placeholder="请输入姓名" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.bd"
                     placeholder="请选择开始日期"
                     label="开始日期"
                     @click="getDatePicker('bd')" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.ed"
                     placeholder="请选择结束日期"
                     label="结束日期"
                     @click="getDatePicker('ed')" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.scd"
                     label="生产地"
                     placeholder="请输入生成地" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.bb"
                     label="班别"
                     placeholder="请输入班别" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.worktype"
                     placeholder="请选择工作方式"
                     label="工作方式"
                     @click="getWorksWay" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.jjscl"
                     placeholder="请选择胶件生产类"
                     label="胶件生产类"
                     @click="getJjscl" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.sjjt"
                     placeholder="请选择实际机台"
                     label="实际机台"
                     @click="getSjjt" />
          <van-field readonly
                     clickable
                     right-icon="edit"
                     name="picker"
                     :value="form.empnamelist"
                     :label="'人员名单'"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="getDatePicker('emp')" />
          <van-field readonly
                     right-icon="edit"
                     v-model="form.empcount"
                     label="人数" />
        </div>
      </van-form>
      <div v-if="dataData.length > 0 && newAutoid != 0"
           class="contentMainTable"
           style="padding-top: 15px; padding-bottom: 15px">
        <div style="width: 100%; overflow: auto">
          <table style="border-collapse: collapse;width: 1430px;">
            <tr style="background: #e4e9f3; height: 40px;border-bottom: 1px solid #ccc;">
              <th colspan="2"
                  style="border-right: 1px solid #ccc">排产日期资料</th>
              <th style="border-right: 1px solid #ccc">机型资料</th>
              <th colspan="2"
                  style="border-right: 1px solid #ccc">型号、订单资料</th>
              <th colspan="4"
                  style="border-right: 1px solid #ccc">模具、部件资料</th>
              <th style="border-right: 1px solid #ccc">材料资料</th>
              <th colspan="5">需生产资料</th>
            </tr>
            <tr style="background: #e4e9f3; height: 40px">
              <th style="border-right: 1px solid #ccc;width: 50px;">序号</th>
              <th style="border-right: 1px solid #ccc">物料上机时间</th>
              <th style="border-right: 1px solid #ccc">实际机台</th>
              <th style="border-right: 1px solid #ccc">型号</th>
              <th style="border-right: 1px solid #ccc">订单系统编号（标签）</th>
              <th style="border-right: 1px solid #ccc">模具编号</th>
              <th style="border-right: 1px solid #ccc">部件名称</th>
              <th style="border-right: 1px solid #ccc">零件规格</th>
              <th style="border-right: 1px solid #ccc">用量/个</th>
              <th style="border-right: 1px solid #ccc">材料材质</th>
              <th style="border-right: 1px solid #ccc">生产数量/台</th>
              <th style="border-right: 1px solid #ccc">未完成/件</th>
              <th style="border-right: 1px solid #ccc">未完成/台</th>
              <th style="border-right: 1px solid #ccc">录入数量</th>
              <th>剩余数</th>
            </tr>
            <tr v-for="(value, key) in dataData"
                :key="key">
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px;">
                  {{ key+1 }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.wlsj_date }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.machinename }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.stylename }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.syscode }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.mjcode }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.bjname }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.specname }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.usenum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.czcl }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.scnum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.not_jnum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.not_tnum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  <input v-model="value.inputnum"
                         style="width: 80px; height: 33px"
                         type="text"
                         class="h5Input"
                         :readonly="formDisable" />
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ (value.not_jnum - value.inputnum)}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="footerBox"
         v-if="showBtn">
      <div class="flexBox"
           v-if="!showCheckBtn">
        <van-button :disabled="canSave"
                    @click="stagingFun"
                    type="primary"
                    style="width: 45%;height: 100%;">暂存</van-button>
        <van-button :disabled="newAutoid == 0"
                    @click="submitFun"
                    type="info"
                    style="width: 45%;height: 100%;">提交</van-button>
      </div>
      <div class="flexBox"
           v-if="showCheckBtn">
        <van-button @click="agreeFun"
                    type="primary"
                    style="width: 45%;height: 100%;">同意</van-button>
        <van-button @click="notAgreeFun"
                    type="info"
                    style="width: 45%;height: 100%;">不同意</van-button>
      </div>
    </div>
    <!-- 选择日期 -->
    <van-popup v-model="showCalendar"
               round
               position="bottom">
      <van-datetime-picker v-model="currentDate"
                           type="date"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onConfirmCal"
                           @cancel="showCalendar = false" />
    </van-popup>
    <!-- 选择开始日期 -->
    <!-- <van-calendar v-model="showBdPicker"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :default-date="bdDefault"
                  @confirm="onBdConfirm" /> -->
    <!-- 选择结束日期 -->
    <!-- <van-calendar v-model="showEdPicker"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :default-date="edDefault"
                  @confirm="onEdConfirm" /> -->
    <!-- 选择工作方式 -->
    <van-popup v-model="showWorkTypePicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="worksWayData"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  :default-index="worksWayDefault"
                  @cancel="showWorkTypePicker = false"
                  @confirm="onConfirmWorksWay" />
    </van-popup>
    <!-- 选择胶件生产类 -->
    <van-popup v-model="showJjsclPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="jjsclData"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showJjsclPicker = false"
                  :default-index="jjsclDefault"
                  @confirm="onConfirmJjscl" />
    </van-popup>
    <!-- 选择实际机台 -->
    <van-popup v-model="showSjjtPicker"
               round
               position="bottom"
               class="sjjt_popup">
      <div class="sjjt_pop_title">
        <van-button type="default"
                    @click="showSjjtPicker = false">{{$t('module.cancel')}}</van-button>
        <van-search shape="round"
                    v-model="searchJiTai"
                    placeholder="搜索关键词"
                    @input="searchJTList" />
        <van-button type="default"
                    @click="onConfirmSjjt">{{$t('module.confirm')}}</van-button>
      </div>
      <div class="sjjt_pop_content">
        <van-checkbox-group v-model="sjjtSelections">
          <van-checkbox :name="it"
                        v-for="(it,idx) in sjjtData"
                        :key="idx+'sjjt'">{{it}}</van-checkbox>
        </van-checkbox-group>
      </div>
      <!-- <van-picker show-toolbar
                  :columns="sjjtData"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showSjjtPicker = false"
                  :default-index="sjjtDefault"
                  @confirm="onConfirmSjjt">
        <template #title>
          <van-search shape="round"
                      v-model="searchJiTai"
                      placeholder="搜索关键词"
                      @input="searchJTList" />
        </template>
      </van-picker> -->
    </van-popup>

    <!-- 选择树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="TreeName"
             module="816"
             :multiseltree="true"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
  </div>
</template>
<script>
// getCurUserInfo
import { parseTime } from '@/utils'
import {
  getCurUserInfo,
  saveZsInputData,
  getZsInputData,
  postZsInputData,
  agreeTmList,
  notAgreeTmlist,
  getZsSjjtList
} from '@api/wxjj.js'
import { GetFilterDropDownList } from "@api/wx.js";
import { Toast } from 'vant';
import PopTree from "@components/PopTree/index.vue";
export default {
  components: {
    PopTree
  },
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      TreeName: '',
      form: {
        empname: "",
        bd: "",
        ed: "",
        scd: "",
        bb: "",
        worktype: "注塑",
        jjscl: "正常",
        sjjt: "",
        empnamelist: "", //人员名单
        empidlist: "", //人员id列表
        empcount: "", //人数 
      },
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      worksWayData: [],
      jjsclData: [],
      sjjtData: [],
      showWorkTypePicker: false,
      showJjsclPicker: false,
      showSjjtPicker: false,
      newAutoid: 0,
      dataData: [],
      bdDefault: new Date(),
      edDefault: new Date(),
      worksWayDefault: 0,
      jjsclDefault: 0,
      sjjtDefault: 0,
      formDisable: false,
      showCheckBtn: false,
      showBtn: true,
      searchJiTai: '', // 机台下拉搜索值
      sjjtSelections: [], // 选中的sjjt
      whichDate: 'bd',
      showCalendar: false,
      currentDate: new Date(),
    }
  },
  created () {
    this.worksWayData = [];
    GetFilterDropDownList({
      moduleno: 816,
      username: this.userInfo.username,
      listsql: "@工作方式",
    }).then((res) => {
      if (res.data.length > 0)
      {
        for (let i = 0; i < res.data.length; i++)
        {
          this.worksWayData.push(res.data[i].dicvalue);
        }
      }
    });
    this.jjsclData = [];
    GetFilterDropDownList({
      moduleno: 816,
      username: this.userInfo.username,
      listsql: "@胶件生产类",
    }).then((res) => {
      if (res.data.length > 0)
      {
        for (let i = 0; i < res.data.length; i++)
        {
          this.jjsclData.push(res.data[i].dicvalue);
        }
      }
    });
    // this.sjjtData = []
    // GetFilterDropDownList({
    //   moduleno: 816,
    //   username: this.userInfo.username,
    //   listsql: "@实际机台",
    // }).then((res) => {
    //   if (res.data.length > 0)
    //   {
    //     for (let i = 0; i < res.data.length; i++)
    //     {
    //       this.sjjtData.push(res.data[i].dicvalue);
    //     }
    //   }
    // });
    this.getZsSjjtListData()
    if (this.$route.query.type == 1)
    {
      this.formDisable = true;
      this.showCheckBtn = true;
    }
    if (this.$route.query.type == 3 || this.$route.query.type == 5)
    {
      this.formDisable = true;
      this.showBtn = false;
    }
    setTimeout(() => {
      if (this.$route.query.autoid)
      {
        this.newAutoid = this.$route.query.autoid;
        this.getZsInputData();
      } else
      {
        this.getCurUserInfo();
      }
    }, 300)
  },
  computed: {
    canSave () {
      if (!this.form.bd)
      {
        return true;
      } else if (!this.form.ed)
      {
        return true;
      } else if (!this.form.worktype)
      {
        return true;
      } else if (!this.form.jjscl)
      {
        return true;
      } else if (!this.form.sjjt)
      {
        return true;
      } else
      {
        return false;
      }
    },
    pageTitle () {
      let title = '注塑登记'
      if (this.$route.query)
      {
        title = this.$route.query.title
      }
      return title
    }
  },
  watch: {
    $route (to, from) {

    }
  },
  methods: {
    formatter (type, val) {
      if (type === "year")
      {
        return val + "年";
      }
      if (type === "month")
      {
        return val + "月";
      }
      if (type === "day")
      {
        return val + "日";
      }
      return val;
    },
    // 获取注塑登记的实际机台列表
    getZsSjjtListData () {
      getZsSjjtList({
        scd: this.form.scd,
        bb: this.form.bb,
        worktype: this.form.worktype,
        jjscl: this.form.jjscl,
        username: this.userInfo.username
      }).then(res => {
        if (res.data.length > 0)
        {
          this.sjjtData = []
          for (let i = 0; i < res.data.length; i++)
          {
            this.sjjtData.push(res.data[i].dicvalue);
          }
        }
      })
    },
    // 机台下拉搜索
    searchJTList () {
      if (this.searchJiTai)
      {
        this.sjjtData = this.sjjtData.filter(it => { return it.indexOf(this.searchJiTai) >= 0 })
      } else
      {
        this.getZsSjjtListData()
      }
    },
    onClickLeft () {
      if (this.$route.query.autoid)
      {
        this.$router.push('/toDoList')
      } else
      {
        this.$router.push('/home')
      }
    },
    getCurUserInfo () {
      getCurUserInfo({
        moduleno: 816,
        username: this.userInfo.username
      }).then(res => {
        const obj = res.data[0];
        this.form.bb = obj.bb;
        this.form.scd = obj.scd;
        this.form.empname = obj.empname;
        this.form.bd = obj.bd
        this.form.ed = obj.ed
      })
    },
    // 树选择回调
    treeSelectValEvent (data, id, obj) {
      if (this.whichDate = 'emp')
      {
        let names = data.map(it => { return it.empname }) || []
        let ids = data.map(it => { return it.empid }) || []
        this.form.empnamelist = names.join(',')//人员名单
        this.form.empidlist = ids.join(',')//人员id列表
        this.form.empcount = data.length//人数  
      }
    },
    // 选择日期
    getDatePicker (val) {
      this.whichDate = val
      if (val == 'emp')
      {
        this.TreeName = 'employee'
        this.$refs.popTreeNode.module = "816";
        this.$refs.popTreeNode.treename = "emptree";
        this.$refs.popTreeNode.editstate = 2;
        this.$refs.popTreeNode.showEmpTreePop("人员名单");
      } else
      {
        if (!this.formDisable)
        {
          this.showCalendar = true;
        }
      }
    },
    onConfirmCal (v) {
      if (this.whichDate == 'bd')
      {
        this.form.bd = parseTime(v, '{y}-{m}-{d}');
      } else
      {
        this.form.ed = parseTime(v, '{y}-{m}-{d}');
      }
      this.showCalendar = false;
    },
    // 获取工作方式
    getWorksWay () {
      if (!this.formDisable)
      {
        this.showWorkTypePicker = true;
      }
    },
    onConfirmWorksWay (value) {
      this.form.worktype = value;
      this.showWorkTypePicker = false;
    },
    // 获取胶件生产类
    getJjscl () {
      if (!this.formDisable)
      {
        this.showJjsclPicker = true;
      }
    },
    getSjjt () {
      if (!this.formDisable)
      {
        this.getZsSjjtListData()
        this.showSjjtPicker = true;
      }
    },
    onConfirmJjscl (value) {
      this.form.jjscl = value;
      this.showJjsclPicker = false;
    },
    onConfirmSjjt () {
      let val = this.sjjtSelections.length > 0 ? this.sjjtSelections.join(',') : ''
      this.form.sjjt = val;
      this.showSjjtPicker = false;
    },
    stagingFun (type) {
      if (!this.form.bd)
      {
        Toast.fail('开始日期不能为空！');
        return false;
      }
      if (!this.form.ed)
      {
        Toast.fail('结束日期不能为空！');
        return false;
      }
      if (!this.form.worktype)
      {
        Toast.fail('工作方式不能为空！');
        return false;
      }
      if (!this.form.jjscl)
      {
        Toast.fail('胶件生产类不能为空！');
        return false;
      }
      if (!this.form.sjjt)
      {
        Toast.fail('实际机台不能为空！');
        return false;
      }
      saveZsInputData({
        autoid: this.newAutoid,
        bd: this.form.bd,
        ed: this.form.ed,
        worktype: this.form.worktype,
        jjscl: this.form.jjscl,
        sjjt: this.form.sjjt,
        remark: "",
        username: this.userInfo.username,
        data: this.dataData,
        empnamelist: this.form.empnamelist,
        empidlist: this.form.empidlist,
        empcount: this.form.empcount
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          if (type == 'submit')
          {
            this.saveData();
          } else
          {
            const obj = res.data[0];
            this.newAutoid = obj.autoid;
            this.getZsInputData();
          }
        }
      })
    },
    getZsInputData () {
      getZsInputData({
        autoid: this.newAutoid,
        username: this.userInfo.username,
      }).then(res => {
        const obj = res.baseinfo[0];
        this.form.bb = obj.bb;
        this.form.scd = obj.scd;
        this.form.empname = obj.empname;
        this.form.bd = obj.bd;
        this.bdDefault = new Date(obj.bd);
        this.form.ed = obj.ed;
        this.edDefault = new Date(obj.ed);
        this.form.worktype = obj.worktype;
        this.form.jjscl = obj.jjscl;
        this.form.sjjt = obj.sjjt
        this.form.empnamelist = obj.empnamelist
        this.form.empidlist = obj.empidlist
        this.form.empcount = obj.empcount
        this.dataData = res.data;
        this.worksWayDefault = this.worksWayData.indexOf(obj.worktype)
        this.jjsclDefault = this.jjsclData.indexOf(obj.jjscl)
        this.sjjtDefault = this.sjjtData.indexOf(obj.sjjt)
        console.log(this.jjsclDefault, 'this.jjsclDefault')
        // 暂存成功
      })
    },
    // 提交
    saveData () {
      postZsInputData({
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('提交成功！');
          this.onClickLeft();
        }
      })
    },
    submitFun () {
      this.stagingFun('submit')
    },
    agreeFun () {
      agreeTmList({
        moduleno: 816,
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        Toast.success('审批成功！');
        this.onClickLeft();
      })
    },
    notAgreeFun () {
      notAgreeTmlist({
        moduleno: 816,
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        Toast.success('审批成功！');
        this.onClickLeft();
      })
    }
  }
}
</script>
<style lang="less" scoped>
.sjjt_popup {
  .sjjt_pop_title {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;
    .van-button {
      border: 0;
    }
  }
  .sjjt_pop_content {
    padding: 0 40px;
    height: 528px;
    overflow-y: auto;
    .van-checkbox-group {
      /deep/.van-checkbox {
        padding: 20px 0;
        // justify-content: space-between;
        border-top: 2px solid #ebedf0;
        .van-checkbox__icon {
        }
        .van-checkbox__label {
          font-size: 40px;
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
.zsCheckInContainer {
  height: 100vh;
  overflow: hidden;

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }

  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    padding-bottom: 80px;
  }
}
.footerBox {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  .flexBox {
    width: 100vw;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 80%;
    display: flex;
    justify-content: space-around;
  }
}
.contentMainTable {
  width: 100%;
  overflow: hidden;
  padding: 0 0.34667rem;
  background: #fff;
  table {
    th {
      font-size: 26px;
      color: #000000;
    }
    td {
      padding: 15px 20px;
      input {
        font-size: 28px;
      }
    }
  }
}

table tr:nth-child(odd) {
  background-color: #f1f5fc;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}

.h5Input {
  width: 180px;
  border: 1px solid #d2d2d2;
  outline: 0 !important;
  border-radius: 10px;
  padding-left: 8px;
}
</style>